import React, { useEffect, useState } from 'react';
import IconTint from 'react-icon-tint';
import checkIcon from '../../assets/icons/ic_check.svg'
import statusIcon from '../../assets/icons/ic_statuspatient.svg'
import maskIcon from '../../assets/icons/ic_mask.svg'
import { collection, getDocs, query, where, doc, getDoc, updateDoc, onSnapshot, orderBy } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { useAtom, useSetAtom } from 'jotai';
import { checkedPatientIds, currentRobot } from '../store/DataStore';
import axios from 'axios';
import qs from 'qs';

const CheckinPopup = ({ isOpen, children, onClose, onSend }) => {
    const [show, setShow] = useState(isOpen);
    const [patientsList, setPatientsList] = useState([]);
    const [savedRobot] = useAtom(currentRobot);

    const confirmAppointmentGPS = async (appointmentId) => {
        console.log("Saved robot: ", savedRobot);
        const practiceIdPath = `Companies/Zeeno_Dev/Dolphin/${savedRobot}`;
        const practiceIdRef = doc(db, practiceIdPath);
    
        // Function to get the practiceid and dolphin_api_key fields
        const getPracticeIdAndApiKey = async () => {
            try {
                const docSnap = await getDoc(practiceIdRef);
                if (docSnap.exists()) {
                    const practiceId = docSnap.data().practiceid;
                    const dolphinApiKey = docSnap.data().dolphin_api_key;
                    console.log('Practice ID:', practiceId);
                    console.log('Dolphin API Key:', dolphinApiKey);
                    return { practiceId, dolphinApiKey };
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error getting document:', error);
            }
        };
    
        const updateAppointment = async (bearerKey) => {
            const { practiceId, dolphinApiKey } = await getPracticeIdAndApiKey();
            let data = qs.stringify({});
            const config = {
                method: 'put',
                url: `https://dolphin-ocean-api-1.servicebus.windows.net/${practiceId}/dapi/AppointmentGpsStatus?appointmentId=${appointmentId}&gpsStatusId=c5782f1a-0557-4eea-8143-ba5a23feb59e`,
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${dolphinApiKey}`,
                },
                data: data
            };
    
            try {
                const response = await axios.request(config);
                console.log("Response data:", JSON.stringify(response.data));
            } catch (error) {
                console.error("Error:", error);
            }
        };
    
        onSnapshot(practiceIdRef, (docSnapshot) => {
            if (docSnapshot.exists()) {
                const data = docSnapshot.data();
                console.log('Bearer updated ' + data.key);
                updateAppointment(data.key);
            } else {
                console.log("Document does not exist!");
            }
        }, (error) => {
            console.error("Error listening to document: ", error);
        });
    
        const appointmentsCollectionPath = `Companies/Zeeno_Dev/Dolphin/${savedRobot}/Appointments`;
        const appointmentsCollectionRef = collection(db, appointmentsCollectionPath);
        const q = query(appointmentsCollectionRef, where("Id", "==", appointmentId));
    
        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const docToUpdate = querySnapshot.docs[0];
                await updateDoc(docToUpdate.ref, {
                    Confirmed: true,
                });
                console.log(`Appointment with ID ${appointmentId} updated to confirmed status: true`);
                handleClose();
            } else {
                console.log(`No appointment found with ID ${appointmentId}`);
            }
        } catch (error) {
            console.error('Error updating confirmed status: ', error);
        }
    };

    useEffect(() => {
        if (savedRobot !== '') {
            console.log('Robot SN:', savedRobot);
            fetchPatients(savedRobot);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [savedRobot]);

    const fetchPatients = async (savedRobot) => {
        const q = query(collection(db, "Companies", "Zeeno_Dev", "Dolphin", savedRobot, "Appointments"), where("Confirmed", "==", false),orderBy("OriginalTime"));
        const querySnapshot = await getDocs(q);
        const patients = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("Patients are " + patients.toString())
        setPatientsList(patients);
    };

    useEffect(() => {
        if (isOpen) {
            setShow(true);
        } else {
            setShow(false);
            setTimeout(onClose, 200);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, onClose]);

    const handleClose = () => {
        setShow(false);
        setTimeout(onClose, 200);
    };

    const handleSend = () => {
        onSend();
        setShow(false);
        setTimeout(onClose, 200);
    }

    return (
        <div
            className={`fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full transition-opacity duration-200 ${show ? 'opacity-100' : 'opacity-0'}`}
            onClick={handleClose}
            style={{ display: show || isOpen ? 'block' : 'none' }}
        >
            <div
                className="relative mx-auto p-6 border w-[75vw] h-[90vh] shadow-lg rounded-md bg-white mt-[5vh]"
                onClick={e => e.stopPropagation()}
            >
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-heebo font-medium">Patient Check-in</h2>
                    <button 
                        onClick={handleClose} 
                        className="text-black bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg p-2 text-sm inline-flex items-center"
                    >
                        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>

                <div className="flex flex-col h-[calc(90vh-8rem)]">
                    <div className="flex flex-col items-center mb-6 space-y-2">
                        {children}
                    </div>

                    <div className="flex-grow overflow-y-auto px-20">
                        {patientsList.map((item, index) => (
                            <div key={index} className="flex justify-center">
                                <PatientModel 
                                    name={item.FirstName} 
                                    time={item.ScheduledTimeBack} 
                                    chair={item.Chair} 
                                    doctor={item.LocationName} 
                                    id={item.Id} 
                                    confirmAppointmentGPS={confirmAppointmentGPS}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="mt-auto pt-4 border-t flex justify-center">
                        {(true) ?
                            <div 
                                className="flex w-fit bg-[#152FF5] py-2.5 px-8 rounded-3xl items-center cursor-pointer hover:bg-opacity-90"
                                onClick={handleSend}
                            >
                                <span className="text-white font-heebo text-lg">Done</span>
                                <IconTint src={checkIcon} className="h-6 ml-2" color="#FFFFFF" />
                            </div>
                            :
                            <div className="flex w-fit bg-[#152FF5] py-2.5 px-8 rounded-3xl items-center opacity-50">
                                <span className="text-white font-heebo text-lg">Done</span>
                                <IconTint src={checkIcon} className="h-6 ml-2" color="#FFFFFF" />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const PatientModel = ({ name, time, chair, doctor, id, confirmAppointmentGPS }) => {
    const [checkInBtn, setCheckInBtn] = useState("Check In");
    const setCheckedIds = useSetAtom(checkedPatientIds);

    const onCheckPressed = () => {
        if (checkInBtn === "Checked") {
            setCheckInBtn("Check In");
        } else {
            setCheckInBtn("Checked");
            console.log("The id added is " + id);
            setCheckedIds((oldCheckedIds) => [...oldCheckedIds, id]);
            confirmAppointmentGPS(id);
        }
    }

    return (
        <div className="flex flex-row h-16 bg-[#8290F8] px-4 bg-opacity-10 rounded-l-[100px] rounded-r-3xl items-center mb-3 border-l-[#8290F8] border-l-[5px] w-[80%]">
            <img alt="patient_status" src={statusIcon} className="h-12" />
            <div className="flex flex-col w-40 ml-3">
                <span className="font-heebo text-lg">{name}</span>
                <span className="font-heebo font-light text-sm">Patient</span>
            </div>
            <div className="flex flex-col w-44">
                <span className="font-heebo text-lg">{time}</span>
                <span className="font-heebo font-light text-sm">Scheduled Time</span>
            </div>
            <div className="flex flex-col w-24">
                <span className="font-heebo text-lg">{chair}</span>
                <span className="font-heebo font-light text-sm">Chair</span>
            </div>
            <img alt="doctor" src={maskIcon} className="h-8 -mt-4" />
            <div className="flex flex-col w-400 ml-3">
                <span className="font-heebo text-lg">{doctor}</span>
                <span className="font-heebo font-light text-sm">Doctor</span>
            </div>
            <div 
                className={`flex w-fit bg-[#152FF5] bg-opacity-80 hover:bg-opacity-100 py-1.5 px-4 rounded-lg items-center cursor-pointer ml-auto ${checkInBtn === "Checked" && 'bg-opacity-100'}`} 
                onClick={onCheckPressed}
            >
                <span className="text-white font-heebo font-light text-base">{checkInBtn}</span>
            </div>
        </div>
    )
}

export default CheckinPopup;