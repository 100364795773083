import React, { useEffect, useState, useCallback } from 'react'
import Header from '../Header'
import speakerIcon from '../../assets/icons/ic_speakersetting.svg'
import muteIcon from '../../assets/icons/ic_muted.svg'
import hardwareIcon from '../../assets/icons/ic_hardwaresetting.svg'
import integrationIcon from '../../assets/icons/ic_integration.svg'
import kioskIcon from '../../assets/icons/ic_kiosksetting.svg'
import actionsIcon from '../../assets/icons/ic_actionsetting.svg'
import batteryIcon from '../../assets/icons/ic_batterysetting.svg'
import langIcon from '../../assets/icons/ic_langsetting.svg'
import settingIcon from '../../assets/icons/ic_settingsetting.svg'
import arrowIcon from '../../assets/icons/ic_arrowright.svg'
import IconTint from 'react-icon-tint';
import { Switch } from '@headlessui/react'
import { useAtom, useSetAtom } from 'jotai'
import { appVersion, currentRobot, kioskTimeout, locationArray, selectedChargePercent, selectedChargedPercent, selectedKioskLoc, selectedWarningPercent, showChargePopup, showChargedPopup, showKioskLocations, showKioskTimeout, showWarningPopup } from '../store/DataStore'
import { collection, doc, getDoc, getDocs, onSnapshot, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase-config'
import CounterPopup from '../popups/CounterPopup'
import LocationsPopup from '../popups/LocationsPopup'
import ukIcon from '../../assets/icons/ic_uk.png'
import frIcon from '../../assets/icons/ic_fr.png'
import spIcon from '../../assets/icons/ic_spain.png'
import axios from 'axios';

const SettingsFragment = ({userName}) => {
  const [savedRobot] = useAtom(currentRobot);
  const docPathBattery = `Companies/Zeeno_Dev/Settings/${savedRobot}/Battery/batterysetting`;
  const docRefBattery = doc(db, docPathBattery);

  const [isKioskTimeoutOpen, setIsKioskTimeoutOpen] = useAtom(showKioskTimeout);
  const [kioskLocationStatus, setKioskLocationStatus] = useAtom(showKioskLocations)
  const [currentTimeout, setCurrentTimeout] = useAtom(kioskTimeout)
  const [locArray] = useAtom(locationArray)
  const [selectedKioskLocation, setSelectedKioskLocation] = useAtom(selectedKioskLoc);

  const [batteryWarningPercent, setBatteryWarningPercent] = useAtom(selectedWarningPercent);
  const [isWarningPopup, setIsWarningPopup] = useAtom(showWarningPopup);

  const [batteryChargePercent, setBatteryChargePercent] = useAtom(selectedChargePercent);
  const [isChargePopup, setIsChargePopup] = useAtom(showChargePopup);

  const [batteryChargedLevel, setBatteryChargedLevel] = useAtom(selectedChargedPercent);
  const [isChargedPopup, setIsChargedPopup] = useAtom(showChargedPopup);


  const onKioskTimeoutStart = async () => {
    setIsKioskTimeoutOpen(false);
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Kiosk/kiosksetting`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        timeout: currentTimeout
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const selectKioskLocation = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Kiosk/kiosksetting`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        location: selectedKioskLocation
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }


  const onBatteryWarningApply = async () => {
    try {
      await updateDoc(docRefBattery, {
        warninglevel_percent: batteryWarningPercent
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onBatteryChargeApply = async () => {
    try {
      await updateDoc(docRefBattery, {
        chargelevel_percent: batteryChargePercent
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onBatteryChargedApply = async () => {
    try {
      await updateDoc(docRefBattery, {
        chargedlevel_percent: batteryChargedLevel
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  return (
    <div className='bg-[#F0F2FF] px-10 py-6 lg:h-[600px] 2xl:h-full overflow-auto'>
      <CounterPopup name='timeout' type={'sec'} currentNum={currentTimeout} setCurrentNum={setCurrentTimeout} isOpen={isKioskTimeoutOpen} onClose={() => setIsKioskTimeoutOpen(false)} onSend={() => onKioskTimeoutStart()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Kiosk Timeout</h2>
      </CounterPopup>
      <LocationsPopup selectedLocation={selectedKioskLocation} setSelectedLocation={setSelectedKioskLocation} isOpen={kioskLocationStatus} onClose={() => setKioskLocationStatus(false)} onSend={() => selectKioskLocation()} locations={locArray}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Kiosk Location</h2>
        <p className="text-md text-gray-800 font-heebo font-light mt-1">Please choose a kiosk location</p>
      </LocationsPopup>
      <CounterPopup name='battery' type={'percent'} currentNum={batteryWarningPercent} setCurrentNum={setBatteryWarningPercent} isOpen={isWarningPopup} onClose={() => setIsWarningPopup(false)} onSend={() => onBatteryWarningApply()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Battery Warning Level</h2>
      </CounterPopup>
      <CounterPopup name='battery' type={'percent'} currentNum={batteryChargePercent} setCurrentNum={setBatteryChargePercent} isOpen={isChargePopup} onClose={() => setIsChargePopup(false)} onSend={() => onBatteryChargeApply()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">Recharge Battery Level</h2>
      </CounterPopup>
      <CounterPopup name='charged' type={'percent'} currentNum={batteryChargedLevel} setCurrentNum={setBatteryChargedLevel} isOpen={isChargedPopup} onClose={() => setIsChargedPopup(false)} onSend={() => onBatteryChargedApply()}>
        <h2 className="text-lg font-medium leading-6 text-[#3C5BD2] font-heebo">End Recharge Battery Level</h2>
      </CounterPopup>
      <Header />
      <div className='flex flex-col w-full 2xl:mt-14 lg:mt-8'>
        <h1 className='font-heebo text-xl  text-[#273a84]'>Settings</h1>
        <SettingsMenu userName={userName} />
      </div>

    </div>
  )
};
export default SettingsFragment

const Dropdown = ({ icon, title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-3">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`cursor-pointer flex items-center justify-between w-full text-left text-xl font-heebo bg-white ${isOpen ? 'rounded-t-lg' : 'rounded-lg hover:bg-[#fbfbff]'
          } focus:outline-none transition duration-300`}
      >
        <div className={`transition-all py-[12px] pl-3 pr-2 ${isOpen
          ? 'bg-[#3C5BD2] rounded-tl-lg rounded-br-lg border-r-4 border-[#3C5BD2] rounded-tr-sm rounded-bl-sm'
          : 'border-r-4 border-[#F0F2FF]'
          }`}>
          <IconTint src={icon} alt='icon' className='h-7 w-7 object-contain' color={isOpen ? '#FFFF' : '#3C5BD2'} />
        </div>
        <div className='flex flex-row w-full pl-4 pr-5 items-center'>
          <span className='w-full'>{title}</span>
          <img src={arrowIcon} alt='arrow' className={`h-4 transition-transform duration-300 ${isOpen ? 'transform -rotate-90' : ''}`} />
        </div>
      </div>
      <div className={`${isOpen ? 'max-h-[1500px]' : 'max-h-0'
        } overflow-hidden transition-max-height duration-700 ease-in-out`}>
        <div className="p-4 bg-white rounded-b-lg">
          {children}
        </div>
      </div>
    </div>
  );
};

const SettingsMenu = ({ userName }) => {
  return (
    <div className="w-full mt-5">
      <Dropdown icon={speakerIcon} title="Audio">
        <AudioSetting />
      </Dropdown>
      <Dropdown icon={hardwareIcon} title="Hardware & Display">
        <HardwareSetting />
      </Dropdown>
      <Dropdown icon={integrationIcon} title="Zeeno AI">
        <IntegrationSetting />
      </Dropdown>
      <Dropdown icon={kioskIcon} title="Kiosk">
        <KioskSetting />
      </Dropdown>
      <Dropdown icon={actionsIcon} title="Integrations">
        <ActionsSetting />
      </Dropdown>
      <Dropdown icon={batteryIcon} title="Battery & Charge">
        <BatterySetting />
      </Dropdown>
      <Dropdown icon={langIcon} title="Languages">
        <LanguageSetting />
      </Dropdown>
      <Dropdown icon={settingIcon} title="Advanced Settings">
        <AdvancedSetting userName={userName}/>
      </Dropdown>
    </div>
  );
};

const AudioSetting = () => {
  const [volume, setVolume] = useState(50);
  const [isVoiceCommand, setIsVoiceCommand] = useState(true);
  const [isVoiceAckno, setIsVoiceAckno] = useState(false);
  const [isObsNotif, setIsObsNotif] = useState(false);
  const [savedRobot] = useAtom(currentRobot);

  useEffect(() => {
    async function getAudioData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (typeof data.volume_amount !== 'undefined' && data.volume_amount !== null) {
            setVolume(data.volume_amount * 10);
          }
          if (data.toggle_voicecommand != null && data.toggle_voiceackno != null && data.toggle_obsnotif != null) {
            setIsVoiceCommand(data.toggle_voicecommand);
            setIsVoiceAckno(data.toggle_voiceackno);
            setIsObsNotif(data.toggle_obsnotif);
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getAudioData();
    setVolume(50);
    setIsVoiceCommand(true);
    setIsVoiceAckno(false); 
    setIsObsNotif(false);
  }, [savedRobot])


  const sendVolumeAmount = async (value) => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        volume_amount: value
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const handleVolumeChange = (event) => {
    setVolume(event.target.value);
    sendVolumeAmount(volume / 10)
  };

  const sendCommands = async (voiceCommand, voiceAckno, obsNotif) => {
    if (savedRobot != null) {
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
      const docRef = doc(db, docPath);
      try {
        await updateDoc(docRef, {
          toggle_voicecommand: voiceCommand,
          toggle_voiceackno: voiceAckno,
          toggle_obsnotif: obsNotif
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    }
  }

  const onVoiceCommandChanged = (checked) => {
    setIsVoiceCommand(checked);
    sendCommands(checked, isVoiceAckno, isObsNotif);
  }

  const onVoiceAcknoChanged = (checked) => {
    setIsVoiceAckno(checked);
    sendCommands(isVoiceCommand, checked, isObsNotif);
  }

  const onObsNotifChanged = (checked) => {
    setIsObsNotif(checked);
    sendCommands(isVoiceCommand, isVoiceAckno, checked);
  }

  return (
    <div className="w-full">
      <h1 className='text-lg font-heebo'>Volume</h1>
      <div className='flex flex-row items-center mt-3'>
        <img src={muteIcon} alt='mute' className='h-5 object-contain' />
        <input
          type="range"
          min="0"
          max="100"
          value={volume}
          onChange={handleVolumeChange}
          className="w-full h-2 bg-[gray-200] rounded-md cursor-pointer appearance-none ml-3"
          style={{
            backgroundImage: `linear-gradient(to right, #3B82F6 ${volume}%, #8290F83A ${volume}%)`
          }}
        />
        <img src={speakerIcon} alt='mute' className='h-5 object-contain ml-3 filter grayscale contrast-200 brightness-0' />
      </div>
      <hr className='mt-6 opacity-60' />

      <div className={`flex flex-row w-full mt-4 justify-between items-center ${(isVoiceAckno || isObsNotif) && 'opacity-40'}`}>
        <h1 className='text-lg font-heebo '>Voice Commands</h1>
        <Switch
          checked={isVoiceCommand}
          disabled={(isVoiceAckno || isObsNotif)}
          onChange={(value) => onVoiceCommandChanged(value)}
          className={`${isVoiceCommand ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 ${!(isVoiceAckno || isObsNotif) && 'cursor-pointer'} rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isVoiceCommand ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light ${(isVoiceAckno || isObsNotif) && 'opacity-40'}`}>Determines whenever the voice command button on Zeeno is enabled</span>
      <hr className='mt-4 opacity-60' />

      <div className={`flex flex-row w-full mt-4 justify-between items-center ${isVoiceCommand && 'opacity-40'}`}>
        <h1 className='text-lg font-heebo '>Voice Acknowledgement</h1>
        <Switch
          checked={isVoiceAckno}
          disabled={isVoiceCommand}
          onChange={(value) => onVoiceAcknoChanged(value)}
          className={`${isVoiceAckno ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 ${!isVoiceCommand && 'cursor-pointer'} rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isVoiceAckno ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light ${isVoiceCommand && 'opacity-40'}`}>Zeeno will automatically listen for voice commands after requesting patient's acknowledgement</span>
      <hr className='mt-4 opacity-60' />

      <div className={`flex flex-row w-full mt-4 justify-between items-center ${isVoiceCommand && 'opacity-40'}`}>
        <h1 className='text-lg font-heebo '>Obstacle Notification</h1>
        <Switch
          checked={isObsNotif}
          disabled={isVoiceCommand}
          onChange={(value) => onObsNotifChanged(value)}
          className={`${isObsNotif ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 ${!isVoiceCommand && 'cursor-pointer'} rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isObsNotif ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light ${isVoiceCommand && 'opacity-40'}`}>Zeeno will beep when encountering obstacles while navigating</span>
    </div>
  );
};

const HardwareSetting = () => {
  const [isHardwareDisabled, setIsHardwareDisabled] = useState(false);
  const [isDisplayFace, setIsDisplayFace] = useState(false);
  const [savedRobot] = useAtom(currentRobot);

  useEffect(() => {
    async function getData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.toggle_hardwaredisable != null) {
            setIsHardwareDisabled(data.toggle_hardwaredisable);
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getData();
    setIsHardwareDisabled(false);
    setIsDisplayFace(false);
  }, [savedRobot])

  const sendCommands = async (hardwareDisable, displayFace) => {
    if (savedRobot != null) {
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
      const docRef = doc(db, docPath);
      try {
        await updateDoc(docRef, {
          toggle_hardwaredisable: hardwareDisable,
          toggle_displayface: displayFace,
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    }
  }

  const onHardwareDisabledChanged = (checked) => {
    setIsHardwareDisabled(checked)
    sendCommands(checked, isDisplayFace);
  }

  const onDisplayFace = (checked) => {
    setIsDisplayFace(checked)
    sendCommands(isHardwareDisabled, checked);
  }

  return (
    <div className='w-full'>
      <div className={`flex flex-row w-full justify-between items-center`}>
        <h1 className='text-lg font-heebo'>Disable Hardware Buttons</h1>
        <Switch
          checked={isHardwareDisabled}
          onChange={(value) => onHardwareDisabledChanged(value)}
          className={`${isHardwareDisabled ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isHardwareDisabled ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-3xl`}>Disables the external hardware buttons on the top of the tablet - It is recommended to switch this setting on to avoid external input from messing with app dynamics.</span>
      <hr className='mt-4 opacity-60' />
      <div className={`flex flex-row w-full justify-between items-center mt-4`}>
        <h1 className='text-lg font-heebo'>Display</h1>
        <Switch
          checked={isDisplayFace}
          onChange={(value) => onDisplayFace(value)}
          className={`${isDisplayFace ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isDisplayFace ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-3xl`}>Determines whether or not Zeeno will display a kid friendly face during patient interactive actions.</span>

    </div>
  )
}

const IntegrationSetting = () => {
  const [savedRobot] = useAtom(currentRobot);
  const [savedPrompt, setSavedPrompt] = useState('');
  const [savedKnowledge, setSavedKnowledge] = useState('');
  const [savedApiKey, setSavedApiKey] = useState('');
  const [isSavedForm, setIsSavedForm] = useState(false);
  const [isFromEmpty, setIsFromEmpty] = useState(false);
  const [model, setModel] = useState('');

  const onSavedPressed = async () => {
    setIsSavedForm(true);
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/bot/prompt`;
    const apiKeyPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/bot/openai`;
    const docRef = doc(db, docPath);
    const apiKeyRef = doc(db, apiKeyPath);
    try {
      await updateDoc(docRef, {
        content: savedPrompt,
        knowledge: savedKnowledge
      });
      await updateDoc(apiKeyRef, {
        api_key: savedApiKey,
        model: model
      });
    } catch (error) {
      console.error('error updating prompt or api key: ', error);
    }
  }

  useEffect(() => {
    if (!savedRobot || savedRobot.trim() === '') {
      console.log("No robot saved");
      return;
    }

    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/bot/prompt`;
    const apiKeyPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/bot/openai`;
    const docRef = doc(db, docPath);
    const apiKeyRef = doc(db, apiKeyPath);

    onSnapshot(docRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const fetchedPrompt = data.content;
        const fetchedKnowledge = data.knowledge;
        const fetchedModel = data.model || '';
        
        setSavedPrompt(fetchedPrompt);
        setSavedKnowledge(fetchedKnowledge);
        setModel(fetchedModel);
      } else {
        console.log("Document does not exist!");
      }
    }, (error) => {
      console.error("Error listening to document: ", error);
    });

    onSnapshot(apiKeyRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const fetchedApiKey = data.api_key;
        
        setSavedApiKey(fetchedApiKey);
      } else {
        console.log("API Key document does not exist!");
      }
    }, (error) => {
      console.error("Error listening to API Key document: ", error);
    });
    setSavedPrompt('');
    setSavedKnowledge('');
    setSavedApiKey('');
    setIsSavedForm(false);
    setIsFromEmpty(true);
    setModel('gpt-4o-mini');
  }, [savedRobot])
  

  useEffect(() => {
    if(savedPrompt === ""){
      setIsFromEmpty(true);
    } else {
      setIsFromEmpty(false);
    }
  }, [savedPrompt, savedKnowledge, savedApiKey])
  
  return (
    <div>
      <h1 className='text-lg font-heebo'>ZeenoAI Prompt</h1>
      <textarea value={savedPrompt} onChange={(e) => {setSavedPrompt(e.target.value); setIsSavedForm(false);}} placeholder='Type prompt here...' className='h-44 bg-[#DCE0FF] p-4 rounded-lg outline-none resize-none font-heebo w-[670px] font-light mt-2'></textarea>
      <h1 className='text-lg font-heebo mt-2'>Zeeno's Knowledge</h1>
      <textarea value={savedKnowledge} onChange={(e) => {setSavedKnowledge(e.target.value); setIsSavedForm(false);}} placeholder='Type Knowledge here...' className='h-36 bg-[#DCE0FF] p-4 rounded-lg outline-none resize-none font-heebo w-[670px] font-light mt-2'></textarea>
      <h1 className='text-lg font-heebo mt-2'>API Key</h1>
      <input type='text' value={savedApiKey} onChange={(e) => {setSavedApiKey(e.target.value); setIsSavedForm(false);}} placeholder='Type API Key here...' className='bg-[#DCE0FF] p-4 rounded-lg outline-none font-heebo w-[670px] font-light mt-2' />
      <h1 className='text-lg font-heebo mt-2'>Model</h1>
      <select value={model} onChange={(e) => {setModel(e.target.value); setIsSavedForm(false);}} className='bg-[#DCE0FF] p-4 rounded-lg outline-none font-heebo w-[670px] font-light mt-2'>
        <option value="gpt-4o">gpt-4o</option>
        <option value="gpt-4o-mini">gpt-4o-mini</option>
      </select>
      <h2
        onClick={() => {
          if(isSavedForm === false && isFromEmpty === false){
            onSavedPressed();
          }
        }}
        className={`px-5 py-2 ${isSavedForm || isFromEmpty ? 'bg-opacity-40' : 'hover:bg-opacity-100 bg-opacity-80 cursor-pointer'} bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3`}>{isSavedForm ? 'Saved' : 'Save'}</h2>
    </div>
  )
}


const KioskSetting = () => {
  const [isTimeout, setIsTimeout] = useState(false);
  const [savedRobot] = useAtom(currentRobot);
  const setIsKioskTimeoutOpen = useSetAtom(showKioskTimeout);

  const [kioskSavedTimeout, setKioskSavedTimeout] = useAtom(kioskTimeout);
  const setKioskLocationStatus = useSetAtom(showKioskLocations);
  const setlocArray = useSetAtom(locationArray);

  const [selectedLocation, setSelectedLocation] = useAtom(selectedKioskLoc);



  async function getLocationList(db) {
    if (savedRobot !== '') {
      const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Locations/backuplocations`;
      const docRef = doc(db, docPath);
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const locations = docSnap.data().locations;
          setlocArray(locations);
          return locations;
        } else {
          console.log("No such document!");
          return [];
        }
      } catch (error) {
        console.error("Error fetching document: ", error);
        return [];
      }
    } else {
      console.log("No robot saved");
      return [];
    }
  }

  useEffect(() => {
    getLocationList(db);
    async function getTimeoutData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Kiosk/kiosksetting`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.toggle_timeout != null) {
            setIsTimeout(data.toggle_timeout);
          }
          if (data.timeout != null) {
            setKioskSavedTimeout(data.timeout);
          }
          if (data.location != null) {
            setSelectedLocation(data.location);
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getTimeoutData();
    setIsTimeout(false);
    setKioskSavedTimeout(0);
    setSelectedLocation('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRobot])


  const onTimeoutChanged = async (checked) => {
    setIsTimeout(checked);
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Kiosk/kiosksetting`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        toggle_timeout: checked
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  return (
    <div className='w-full'>
      <hr className='mt-4 opacity-60' />
      <div className={`flex flex-row w-full justify-between items-center`}>
        <h1 className='text-lg font-heebo mt-4'>Kiosk Timeout</h1>
        <Switch
          checked={isTimeout}
          onChange={(value) => onTimeoutChanged(value)}
          className={`${isTimeout ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isTimeout ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light max-w-3xl`}>Amount of time Zeeno must be idle before automatically submitting the kiosk action.</span>
      <h2 className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer' onClick={() => setIsKioskTimeoutOpen(true)}>{`${kioskSavedTimeout} sec`}</h2>

      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Kiosk Location</h1>
      <span className={`font-heebo font-light`}>Location at which to perform the kiosk action (i.e. navigate to this location and then perform the kiosk action)</span>
      <h2 onClick={() => setKioskLocationStatus(true)} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>{selectedLocation}</h2>
    </div>
  )
}



const ActionsSetting = () => {
  const [savedRobot] = useAtom(currentRobot);
  
  // Existing state variables
  const [practiceId, setPracticeId] = useState('');
  const [isSavedForm, setIsSavedForm] = useState(false);
  const [allLocations, setAllLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [integrationStatus, setIntegrationStatus] = useState('');
  const [codeReception, setCodeReception] = useState('');
  const [codeScheduled, setCodeScheduled] = useState('');

  // New state variables for Integration Settings
  const [bdaycheckMsg, setBdaycheckMsg] = useState('');
  const [checkInEnd, setCheckInEnd] = useState('');
  const [checkInStart, setCheckInStart] = useState('');
  const [dynamicRange, setDynamicRange] = useState('');
  const [earlycheckMsg, setEarlycheckMsg] = useState('');
  const [headercheckMsg, setHeadercheckMsg] = useState('');
  const [latecheckMsg, setLatecheckMsg] = useState('');

  useEffect(() => {
    async function fetchData() {
      if (!savedRobot) return;

      const docRef = doc(db, `Companies/Zeeno_Dev/Dolphin/${savedRobot}`);
      const locationsRef = collection(db, `Companies/Zeeno_Dev/Dolphin/${savedRobot}/Locations`);
      const integrationSettingsRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Integration/integrationsetting`);

      try {
        // Fetch main document
        const docSnapshot = await getDoc(docRef);
        // Fetch locations
        const locationsSnapshot = await getDocs(locationsRef);
        // Fetch integration settings
        const integrationSnapshot = await getDoc(integrationSettingsRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setPracticeId(data.practiceid || '');

          const initialSelectedLocations = (data.practiceLocation || []).map(name => {
            const location = locationsSnapshot.docs.find(doc => doc.data().Name === name);
            return location ? { id: location.id, name: location.data().Name } : null;
          }).filter(Boolean);

          setSelectedLocations(initialSelectedLocations);
          setIsSavedForm(true);

          setCodeReception(data.codeReception || '');
          setCodeScheduled(data.codeScheduled || '');
        }

        // Fetch all locations
        const locationsData = [];
        locationsSnapshot.forEach(doc => {
          const locationData = doc.data();
          locationsData.push({ id: doc.id, name: locationData.Name });
        });
        setAllLocations(locationsData);

        // Fetch integration settings if available
        if (integrationSnapshot.exists()) {
          const integrationData = integrationSnapshot.data();
          setBdaycheckMsg(integrationData.bdaycheck_msg || '');
          setCheckInEnd(integrationData.check_in_end !== undefined ? integrationData.check_in_end.toString() : '');
          setCheckInStart(integrationData.check_in_start !== undefined ? integrationData.check_in_start.toString() : '');
          setDynamicRange(integrationData.dynamic_range !== undefined ? integrationData.dynamic_range.toString() : '');
          setEarlycheckMsg(integrationData.earlycheck_msg || '');
          setHeadercheckMsg(integrationData.headercheck_msg || '');
          setLatecheckMsg(integrationData.latecheck_msg || '');
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }

    fetchData();
    setPracticeId('');
    setIsSavedForm(false);
    setAllLocations([]);
    setSelectedLocations([]);
    setIntegrationStatus('');
    setCodeReception('');
    setCodeScheduled('');
    setBdaycheckMsg('');
    setCheckInEnd('');
    setCheckInStart('');
    setDynamicRange('');
    setEarlycheckMsg('');
    setHeadercheckMsg('');
    setLatecheckMsg('');
  }, [savedRobot]);

  // Handler functions for existing fields
  const handlePracticeIdChange = async (e) => {
    const newPracticeId = e.target.value;
    setPracticeId(newPracticeId);
    setIsSavedForm(false);

    try {
      const docRef = doc(db, `Companies/Zeeno_Dev/Dolphin/${savedRobot}`);
      await updateDoc(docRef, { practiceid: newPracticeId });
    } catch (error) {
      console.error("Error updating practice ID: ", error);
    }
  };

  const handleCodeReceptionChange = async (e) => {
    const newCodeReception = e.target.value;
    setCodeReception(newCodeReception);
    setIsSavedForm(false);

    try {
      const docRef = doc(db, `Companies/Zeeno_Dev/Dolphin/${savedRobot}`);
      await updateDoc(docRef, { codeReception: newCodeReception });
    } catch (error) {
      console.error("Error updating codeReception: ", error);
    }
  };

  const handleCodeScheduledChange = async (e) => {
    const newCodeScheduled = e.target.value;
    setCodeScheduled(newCodeScheduled);
    setIsSavedForm(false);

    try {
      const docRef = doc(db, `Companies/Zeeno_Dev/Dolphin/${savedRobot}`);
      await updateDoc(docRef, { codeScheduled: newCodeScheduled });
    } catch (error) {
      console.error("Error updating codeScheduled: ", error);
    }
  };

  const handleLocationChange = (location) => {
    const updatedLocations = selectedLocations.find(loc => loc.id === location.id)
      ? selectedLocations.filter(loc => loc.id !== location.id)
      : [...selectedLocations, location];

    setSelectedLocations(updatedLocations);
    setIsSavedForm(false);
  };

  // Handler functions for new Integration Settings fields
  const handleBdaycheckMsgChange = (e) => {
    setBdaycheckMsg(e.target.value);
    setIsSavedForm(false);
  };

  const handleCheckInEndChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Ensure only numbers are entered
      setCheckInEnd(value);
      setIsSavedForm(false);
    }
  };

  const handleCheckInStartChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Ensure only numbers are entered
      setCheckInStart(value);
      setIsSavedForm(false);
    }
  };

  const handleDynamicRangeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Ensure only numbers are entered
      setDynamicRange(value);
      setIsSavedForm(false);
    }
  };

  const handleEarlycheckMsgChange = (e) => {
    setEarlycheckMsg(e.target.value);
    setIsSavedForm(false);
  };

  const handleHeadercheckMsgChange = (e) => {
    setHeadercheckMsg(e.target.value);
    setIsSavedForm(false);
  };

  const handleLatecheckMsgChange = (e) => {
    setLatecheckMsg(e.target.value);
    setIsSavedForm(false);
  };

  const onSavedPressed = async () => {
    setIsSavedForm(true);
    const mainDocRef = doc(db, `Companies/Zeeno_Dev/Dolphin/${savedRobot}`);
    const integrationSettingsRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Integration/integrationsetting`);

    try {
      // Update main document
      const practiceLocation = selectedLocations.map(loc => loc.name);
      await updateDoc(mainDocRef, { 
        practiceid: practiceId, 
        practiceLocation, 
        codeReception, 
        codeScheduled 
      });

      // Update integration settings
      const integrationData = {
        bdaycheck_msg: bdaycheckMsg,
        check_in_end: checkInEnd ? checkInEnd : '0',
        check_in_start: checkInStart ? checkInStart : '0',
        dynamic_range: dynamicRange ? dynamicRange : '0',
        earlycheck_msg: earlycheckMsg,
        headercheck_msg: headercheckMsg,
        latecheck_msg: latecheckMsg
      };
      await updateDoc(integrationSettingsRef, integrationData);

    } catch (error) {
      console.error('Error saving data: ', error);
    }
  };

  const checkIntegration = async () => {
    const data = JSON.stringify({
      practiceId: practiceId,
      robotSN: savedRobot
    });

    const config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://zeeno-api.azurewebsites.net/bot-appointments',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    try {
      const response = await axios.request(config);
      console.log(JSON.stringify(response.data));
      setIntegrationStatus('Success');
    } catch (error) {
      console.error(error);
      setIntegrationStatus('Error');
    }
  };

  return (
    <div className='flex flex-col'>
      {/* Existing Fields */}
      <h1 className='text-lg font-heebo mt-4'>3rd Party Integration Setting</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>
        Enter the Dolphin PracticeID to integrate with the Dolphin system.
      </span>
      <input
        type="text"
        value={practiceId}
        onChange={handlePracticeIdChange}
        className='px-5 py-2 border border-gray-300 rounded-lg mt-3'
        placeholder='Enter Dolphin PracticeID'
      />

      <h1 className='text-lg font-heebo mt-4'>Practice Locations</h1>
      <div className='px-5 py-2 border border-gray-300 rounded-lg mt-3'>
        {allLocations.map((location, index) => (
          <label key={index} className='flex items-center space-x-3'>
            <input
              type="checkbox"
              checked={selectedLocations.some(loc => loc.id === location.id)}
              onChange={() => handleLocationChange(location)}
              className="custom-checkbox"
            />
            <span className="text-gray-700 font-heebo">{location.name}</span>
          </label>
        ))}
      </div>
      
      {/* Existing Code Reception Field */}
      <h1 className='text-lg font-heebo mt-4'>Code Reception</h1>
      <input
        type="text"
        value={codeReception}
        onChange={handleCodeReceptionChange}
        className='px-5 py-2 border border-gray-300 rounded-lg mt-3'
        placeholder='Enter Code Reception'
      />

      {/* Existing Code Scheduled Field */}
      <h1 className='text-lg font-heebo mt-4'>Code Scheduled</h1>
      <input
        type="text"
        value={codeScheduled}
        onChange={handleCodeScheduledChange}
        className='px-5 py-2 border border-gray-300 rounded-lg mt-3'
        placeholder='Enter Code Scheduled'
      />

      {/* New Integration Settings Fields */}
      <h1 className='text-lg font-heebo mt-4'>Integration Settings</h1>
      
      {/* Birthday Check Message */}
      <label className='mt-3'>
        <span className='font-heebo'>Birthday Check Message</span>
        <input
          type="text"
          value={bdaycheckMsg}
          onChange={handleBdaycheckMsgChange}
          className='px-5 py-2 border border-gray-300 rounded-lg mt-1 w-full'
          placeholder='Enter Birthday Check Message'
        />
      </label>

      {/* Check-In Start */}
      <label className='mt-3'>
        <span className='font-heebo'>Check-In Start (Number)</span>
        <input
          type="number"
          value={checkInStart}
          onChange={handleCheckInStartChange}
          className='px-5 py-2 border border-gray-300 rounded-lg mt-1 w-full'
          placeholder='Enter Check-In Start Time'
        />
      </label>

      {/* Check-In End */}
      <label className='mt-3'>
        <span className='font-heebo'>Check-In End (Number)</span>
        <input
          type="number"
          value={checkInEnd}
          onChange={handleCheckInEndChange}
          className='px-5 py-2 border border-gray-300 rounded-lg mt-1 w-full'
          placeholder='Enter Check-In End Time'
        />
      </label>

      {/* Dynamic Range */}
      <label className='mt-3'>
        <span className='font-heebo'>Dynamic Range (Number)</span>
        <input
          type="number"
          value={dynamicRange}
          onChange={handleDynamicRangeChange}
          className='px-5 py-2 border border-gray-300 rounded-lg mt-1 w-full'
          placeholder='Enter Dynamic Range'
        />
      </label>

      {/* Early Check Message */}
      <label className='mt-3'>
        <span className='font-heebo'>Early Check Message</span>
        <input
          type="text"
          value={earlycheckMsg}
          onChange={handleEarlycheckMsgChange}
          className='px-5 py-2 border border-gray-300 rounded-lg mt-1 w-full'
          placeholder='Enter Early Check Message'
        />
      </label>

      {/* Header Check Message */}
      <label className='mt-3'>
        <span className='font-heebo'>Header Check Message</span>
        <input
          type="text"
          value={headercheckMsg}
          onChange={handleHeadercheckMsgChange}
          className='px-5 py-2 border border-gray-300 rounded-lg mt-1 w-full'
          placeholder='Enter Header Check Message'
        />
      </label>

      {/* Late Check Message */}
      <label className='mt-3'>
        <span className='font-heebo'>Late Check Message</span>
        <input
          type="text"
          value={latecheckMsg}
          onChange={handleLatecheckMsgChange}
          className='px-5 py-2 border border-gray-300 rounded-lg mt-1 w-full'
          placeholder='Enter Late Check Message'
        />
      </label>

      {/* Integration Status Check Section */}
      <h1 className='text-lg font-heebo mt-4'>Integration Status Check</h1>
      <button
        onClick={checkIntegration}
        className='px-5 py-2 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3'
      >
        Check Integration
      </button>
      {integrationStatus && (
        <div className={`mt-3 text-lg font-heebo ${integrationStatus === 'Success' ? 'text-green-500' : 'text-red-500'}`}>
          {integrationStatus}
        </div>
      )}

      {/* Save Button */}
      <button
        onClick={onSavedPressed}
        className={`px-5 py-2 ${isSavedForm ? 'bg-opacity-40' : 'hover:bg-opacity-100 bg-opacity-80 cursor-pointer'} bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3`}
      >
        {isSavedForm ? 'Saved' : 'Save'}
      </button>
    </div>
  );
};






const BatterySetting = () => {
  const [savedRobot] = useAtom(currentRobot);

  const setOpenWarningPopup = useSetAtom(showWarningPopup);
  const [warningLevel, setWarningLevel] = useAtom(selectedWarningPercent);

  const setOpenChargePopup = useSetAtom(showChargePopup);
  const [chargeLevel, setChargeLevel] = useAtom(selectedChargePercent);

  const setOpenChargedPopup = useSetAtom(showChargedPopup);
  const [chargedLevel, setChargedLevel] = useAtom(selectedChargedPercent);
  const [isCharged, setIsCharged] = useState(false);


  useEffect(() => {
    async function getData() {
      try {
        const docRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Battery/batterysetting`);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          if (data.warninglevel_percent != null) {
            setWarningLevel(data.warninglevel_percent)
          }
          if (data.chargelevel_percent != null) {
            setChargeLevel(data.chargelevel_percent)
          }
          if (data.chargedlevel_percent != null) {
            setChargedLevel(data.chargedlevel_percent)
          }
        } else {
          console.log("Document does not exist");
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    }
    getData();
    setWarningLevel(0);
    setChargeLevel(0);
    setChargedLevel(0);
    setIsCharged(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedRobot])


  return (
    <div className='flex flex-col'>
      <h1 className='text-lg font-heebo'>Low Battery Warning</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>At this battery percent, Zeeno will announce that battery is low and it will soon have to automatically return to home base to charge</span>
      <h2 onClick={() => setOpenWarningPopup(true)} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>{`${warningLevel}%`}</h2>
      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Recharge Battery Level</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>At this battery percent, Zeeno will automatically place the “charge” task on the queue. Practically, this means that Zeeno will continue to complete all currently assigned tasks and afterwards head to home base to charge.</span>
      <h2 onClick={() => setOpenChargePopup(true)} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>{`${chargeLevel}%`}</h2>
      <hr className='mt-4 opacity-60' />
      <div className={`flex flex-row w-full justify-between items-center mt-4`}>
        <h1 className='text-lg font-heebo'>End Recharge Battery Level</h1>
        <Switch
          checked={isCharged}
          onChange={(value) => setIsCharged(value)}
          className={`${isCharged ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isCharged ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>While the charging action is enabled Zeeno will accept additional goals, but will not execute them until the charge behavior is acknowledged, canceled, or the battery reaches this set threshold - upon which point the charge task will automatically terminate.</span>
      <h2 onClick={() => setOpenChargedPopup(true)} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>{`${chargedLevel}%`}</h2>
    </div>
  )
}

const LanguageSetting = () => {
  const languages = [
    { code: ukIcon, label: 'English (UK)' },
    { code: frIcon, label: 'Français' },
    { code: spIcon, label: 'Español' },
  ];
  // Initialize the state with the first language selected
  const [selectedLanguages, setSelectedLanguages] = useState({
    [languages[0].code]: true,
  });

  const toggleLanguage = (selectedCode) => {
    setSelectedLanguages(prevSelected => {
      // Create a new object with all values set to false
      const resetLanguages = Object.keys(prevSelected).reduce((acc, code) => {
        acc[code] = false;
        return acc;
      }, {});

      // Set only the selected language to true
      return { ...resetLanguages, [selectedCode]: true };
    });
  };

  return (
    <div className="flex flex-col">
      {languages.map((language) => (
        <label key={language.code} className={`flex items-center space-x-3 ${(selectedLanguages[language.code] || false) && 'bg-slate-50'} p-3 cursor-pointer rounded-lg`}>
          <input
            type="checkbox"
            checked={selectedLanguages[language.code] || false}
            onChange={() => toggleLanguage(language.code)}
            className="form-checkbox h-5 w-5 mr-2 ml-2 cursor-pointer"
          />
          <img
            src={language.code}
            alt={`${language.label} Flag`}
            className="h-10 w-16 object-contain"
          />
          <span className="text-gray-700 font-heebo text-xl">{language.label}</span>
        </label>
      ))}
    </div>
  )
}

const ApplicationUpdates = () => {
  const [versions, setVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [savedRobot] = useAtom(currentRobot);
  const [updateSent, setUpdateSent] = useState(false); // Add this state
  const [appVersionSaved] = useAtom(appVersion);

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const versionsCollection = collection(db, 'Companies/Zeeno_Dev/app_updates');
        const versionsSnapshot = await getDocs(versionsCollection);
        const versionsData = [];
        versionsSnapshot.forEach((doc) => {
          versionsData.push({
            id: doc.id,
            ...doc.data()
          });
        });
        setVersions(versionsData);
      } catch (error) {
        console.error('Error fetching versions:', error);
      }
    };

    fetchVersions();
  }, []);

  const handleSendUpdate = async () => {
    if (!selectedVersion || !savedRobot) return;

    const settingsPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const settingsRef = doc(db, settingsPath);

    try {
      await updateDoc(settingsRef, {
        apk_url: selectedVersion.apk_url,
        app_version: selectedVersion.app_version,
        apk_version_code: selectedVersion.apk_version_code,
        is_update_available: true
      });
      console.log('Update sent successfully!');
      console.log('Selected version:', selectedVersion);
      setUpdateSent(true); // Set to true when update is sent
      // Reset after 3 seconds
      setTimeout(() => {
        setUpdateSent(false);
      }, 3000);
    } catch (error) {
      console.error('Error sending update:', error);
    }
  };

  return (
    <div>
      <h1 className='text-lg font-heebo'>Application Updates</h1>
        <span className='font-heebo font-light inline-block max-w-5xl'>
          Send Zeeno version update <span className='text-gray-400'>(Current App Version: {appVersionSaved})</span>
        </span>
      <div className='mt-3 flex flex-col gap-3'>
        <select 
          className='px-5 py-2 border border-gray-300 rounded-lg font-heebo'
          onChange={(e) => {
            const version = versions.find(v => v.id === e.target.value);
            setSelectedVersion(version);
          }}
          value={selectedVersion?.id || ''}
        >
          <option value="">Select version</option>
          {versions.map((version) => (
            <option key={version.id} value={version.id}>
              {version.app_version}-{version.apk_version_code}
            </option>
          ))}
        </select>
        
        {selectedVersion && (
          <div className='bg-gray-50 p-3 rounded-lg'>
            <p className='font-heebo font-light'>{selectedVersion.versionDescription}</p>
          </div>
        )}

        <div className='flex flex-row items-center gap-3'>
          <button
            onClick={handleSendUpdate}
            disabled={!selectedVersion}
            className={`px-5 py-2 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg
              ${!selectedVersion ? 'opacity-50 cursor-not-allowed' : 'hover:bg-opacity-100 bg-opacity-80 cursor-pointer'}`}
          >
            Send Update
          </button>
          {updateSent && (
            <span className='text-green-500 font-heebo'>Update sent successfully!</span>
          )}
        </div>
      </div>
      <hr className='mt-4 opacity-60' />
    </div>
  );
};

const AdvancedSetting = ({userName}) => {
  const [isTopBar, setIsTopBar] = useState(null);
  const [savedRobot] = useAtom(currentRobot);
  const [appVersionSaved, setAppVersionSaved] = useAtom(appVersion);

  console.log('userName', userName);

  const onRestartClick = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        toggle_restart: true
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onUpdateMapClick = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        toggle_updatemap: true
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onBackupLocClick = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        toggle_backuploc: true
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onRestoreLocClick = async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`;
    const docRef = doc(db, docPath);
    try {
      await updateDoc(docRef, {
        toggle_restoreloc: true
      });
    } catch (error) {
      console.error('error updated location: ', error);
    }
  }

  const onChangeTopBar = useCallback(async () => {
    const docPath = `Companies/Zeeno_Dev/Settings/${savedRobot}/Advanced/advancedsetting`;
    const docRef = doc(db, docPath);

    if (isTopBar) {
      try {
        await updateDoc(docRef, {
          toggle_topbar: true
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    } else {
      try {
        await updateDoc(docRef, {
          toggle_topbar: false
        });
      } catch (error) {
        console.error('error updated location: ', error);
      }
    }
  }, [savedRobot, isTopBar]);
 

  useEffect(() => {
    // Reset states
    setIsTopBar(null);
    setAppVersionSaved('');
  
    // Only fetch data if we have a valid robot
    if (!savedRobot) return;
  
    async function fetchData() {
      try {
        // Fetch advanced settings data
        const advancedDocRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Advanced/advancedsetting`);
        const advancedSnapshot = await getDoc(advancedDocRef);
  
        // Fetch controller settings data
        const controllerDocRef = doc(db, `Companies/Zeeno_Dev/Settings/${savedRobot}/Controller/settings`);
        const controllerSnapshot = await getDoc(controllerDocRef);
  
        // Update topBar state if exists
        if (advancedSnapshot.exists()) {
          const data = advancedSnapshot.data();
          if (data.toggle_topbar != null) {
            setIsTopBar(data.toggle_topbar);
          }
        }
  
        // Update app version if exists
        if (controllerSnapshot.exists()) {
          const data = controllerSnapshot.data();
          if (data.app_version != null) {
            setAppVersionSaved(data.app_version);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    fetchData();
  }, [savedRobot, setAppVersionSaved]);
  
  // Keep this effect for handling topBar changes
  useEffect(() => {
    if (isTopBar != null) {
      onChangeTopBar();
    }
  }, [isTopBar, onChangeTopBar]);

  return (
    <div>
      {(userName === 'Zeeno Admin' || userName === 'Zeeno Support') && (
    <ApplicationUpdates />
      )}
      <h1 className='text-lg font-heebo'>Send Logs</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>Pressing this button sends Zeeno’s internal logs to the server. It is recommended to press this button when an issue occurs so information on the problem can be gathered and addressed quickly.</span>
      <h2 onClick={() => console.log("sent logs")} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>Send Logs</h2>
      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Restart App</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>Pressing this button restarts the app, leaving it in a fresh state. It is recommended to press this button if a breaking issue occurs which leaves the user unable to issue commands to Zeeno.</span>
      <h2 onClick={() => onRestartClick()} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>Restart</h2>
      <hr className='mt-4 opacity-60' />
      <div className={`flex flex-row w-full justify-between items-center mt-4`}>
        <h1 className='text-lg font-heebo'>Show Top Bar</h1>
        <Switch
          checked={isTopBar}
          onChange={(value) => setIsTopBar(value)}
          className={`${isTopBar ? 'bg-[#2C2C2C] bg-opacity-40' : 'bg-[#e6eaff]'}
          relative inline-flex h-5 w-10 items-center shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
        >
          <span className="sr-only">Use setting</span>
          <span
            aria-hidden="true"
            className={`${isTopBar ? 'translate-x-[18px] bg-[#152FF5]' : 'translate-x-0 bg-[#2C2C2C]'}
            pointer-events-none inline-block h-5 w-5 transform rounded-full  shadow-lg ring-0 transition duration-200 ease-in-out`}
          />
        </Switch>
      </div>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>By activating this toggle pulls down the top bar of the native tablet, this can be used in case the user desires to navigate external to the app for whatever reason.</span>
      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Update Map</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>Pressing this button sends a copy of the map to Zeeno Robotics server. Should be used if the map on the webapp does not match the one displayed on the robot</span>
      <h2 onClick={() => onUpdateMapClick()} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>Update Map</h2>
      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Backup Locations</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>Saves all currently saved locations to a collection and backs it up on the server</span>
      <h2 onClick={() => onBackupLocClick()} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>Backup Locations</h2>
      <hr className='mt-4 opacity-60' />
      <h1 className='text-lg font-heebo mt-4'>Restore Locations</h1>
      <span className={`font-heebo font-light inline-block max-w-5xl`}>Overwrites all existing locations and replaces them with the backed up collection</span>
      <h2 onClick={() => onRestoreLocClick()} className='px-5 py-2 hover:bg-opacity-100 bg-opacity-80 bg-[#3C5BD2] text-white font-heebo w-fit rounded-lg mt-3 cursor-pointer'>Restore Locations</h2>
      <hr className='mt-4 opacity-60' />
      <div className='flex flex-row py-5 w-full justify-between pr-4'>
        <span className='text-xl font-heebo'>Serial Number</span>
        <span className='text-xl font-heebo font-light text-[#6879ff]'>{savedRobot != null ? savedRobot : 'Open Robot'}</span>
      </div>
      <hr className='opacity-10 border-t border-[#6879ff]' />
      <div className='flex flex-row pt-5 pb-2 w-full justify-between pr-4'>
        <span className='text-xl font-heebo'>App Version</span>
        <span className='text-xl font-heebo font-light text-[#6879ff]'>{appVersionSaved != null ? appVersionSaved : 'Open App'}</span>
      </div>
    </div>
  )
} 